import styled from "styled-components";


export const Container = styled.footer`
  background: rgba( 60, 55, 55, 0.3 );
  padding: 3rem 0rem;
  margin-top: 10rem;
  display: flex;
  align-items: center;
  justify-content: center;

`